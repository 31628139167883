import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  accessConfigs: {},
  activeTab: null,
  loading: false,
  error: null,
};

export const fetchAccessConfigs = createAsyncThunk(
  'accessConfig/fetchAccessConfigs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/configs/getAccessConfigJSON`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const accessConfigSlice = createSlice({
  name: 'accessConfigSlice',
  initialState,
  reducers: {
    fetchAccessConfigsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAccessConfigsSuccess(state, action) {
      state.loading = false;
      state.accessConfigs = action.payload.accessConfigs;
      state.activeTab = Object.keys(action.payload.accessConfigs)[0];
    },
    fetchAccessConfigsFailure(state, action) {
      state.loading = false;
      state.error = action.payload.error;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload.tabName;
    },
    toggleAccessConfig(state, action) {
      
      const { tab, accessKey } = action.payload;
      console.log('accessKey', accessKey);
      state.accessConfigs[tab][accessKey] = !state.accessConfigs[tab][accessKey];
    },
    saveAccessConfig(state, action) {
      const { tab, selectedItems } = action.payload;
      console.log('selectedItems', selectedItems);
      // Assuming accessConfigs is an object with tabs as keys, you can update the accessConfigs for the specified tab
      state.accessConfigs[tab] = selectedItems;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAccessConfigs.fulfilled, (state, action) => {
        state.loading = false;
        state.accessConfigs = action.payload;
        state.activeTab = Object.keys(action.payload)[0];
      })
      .addCase(fetchAccessConfigs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload.message : 'Failed to fetch access configurations';
      });
  },
});

export const {
  fetchAccessConfigsRequest,
  fetchAccessConfigsSuccess,
  fetchAccessConfigsFailure,
  setActiveTab,
  saveAccessConfig,
  toggleAccessConfig, // Add this line to export the toggleAccessConfig action creator
} = accessConfigSlice.actions;
export default accessConfigSlice.reducer;
