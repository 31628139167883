import { combineReducers } from 'redux';
import doctorSlice from './docSlice.js'; // Update the import path if necessary
import userCategoriesSlice from './admin/getAllCategories.js'
import getUserByIdSlice from './admin/getCategoriesDataById.ts'
import testGroupSlice from './pathology/getAllTestGroups.ts'
import testGroupSliceByID from './pathology/gettestByGroupId.ts'
import  departmentsSlice  from './admin/getAllDepartments.js';
import profileSlice from './profileSlice.js';
import assignedDoctorsSlice from './assignedDoctorsSlice.js';
import reportsDocSlice  from './reportsDocSlice.js';
import accessConfigSlice from './config/accessConfigSlice.js';

const rootReducer = combineReducers({
  doctorSlice,
  userCategoriesSlice,
  getUserByIdSlice,
  testGroupSlice,
  testGroupSliceByID,
  departmentsSlice,
  profileSlice,
  assignedDoctorsSlice,
  reportsDocSlice,
  accessConfigSlice
  
  // Add other reducers here if you have any
});

export default rootReducer;
