import React from 'react';

const ReportIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3 4.8C14.3 3.0083 14.3 2.1134 13.7433 1.5567C13.1866 1 12.2917 1 10.5 1C8.7083 1 7.8134 1 7.2567 1.5567C6.7 2.1134 6.7 3.0083 6.7 4.8M1 12.4C1 8.81755 1 7.02585 2.1134 5.9134C3.22585 4.8 5.01755 4.8 8.6 4.8H12.4C15.9824 4.8 17.7741 4.8 18.8866 5.9134C20 7.02585 20 8.81755 20 12.4C20 15.9824 20 17.7741 18.8866 18.8866C17.7741 20 15.9824 20 12.4 20H8.6C5.01755 20 3.22585 20 2.1134 18.8866C1 17.7741 1 15.9824 1 12.4Z" stroke="#2A2A2A" strokeWidth="1.425"/>
      <path d="M10.4996 15.5664C11.5494 15.5664 12.3996 14.8577 12.3996 13.9837C12.3996 13.1097 11.5494 12.4001 10.4996 12.4001C9.44986 12.4001 8.59961 11.6914 8.59961 10.8164C8.59961 9.94245 9.44986 9.23375 10.4996 9.23375M10.4996 15.5664C9.44986 15.5664 8.59961 14.8577 8.59961 13.9837M10.4996 15.5664V16.2001M10.4996 9.23375V8.6001M10.4996 9.23375C11.5494 9.23375 12.3996 9.94245 12.3996 10.8164" stroke="#2A2A2A" strokeWidth="1.425" strokeLinecap="round"/>
    </svg>
  );
};

export default ReportIcon;
