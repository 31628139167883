import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import AuthRoute from '../../components/AuthRoute';
import PrivatePaths from '../ProtectedRoutes';
import PublicPaths from '../PublicRoutes';
import AuthPaths from '../AuthRoutes';
import Layout from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from '../../redux/reducers/profileSlice';
import { Roles } from '../../enums';
import Preloader from '../../components/Preloader';
import Restrict from '../../pages/Restrict';


interface RouteItem {
  path: string;
  Content: React.ComponentType<any>;
}

const RoutesHOC: React.FC = () => {
  const [restrictPrivatePaths,setRestrictPrivatePaths] = useState<any>([]);
 
  const profile = useSelector((state : any) => state.profileSlice.profile)
  const loading = useSelector((state : any) => state.profileSlice.loading);
  const category = profile?.data?.userType
const userAccess = profile?.data?.roles;

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProfileData() as any)
  },[])
  useEffect(()=>{
    filterAdminRoutes()
 
 
// filterRoutes();
  },[loading])
  const filterAdminRoutes = () => {
    if (category?.includes('superAdmin')) {
      setRestrictPrivatePaths(PrivatePaths);
    } else {
      let index = PrivatePaths.findIndex(item => item.path === '/super-admin-dashboard');
      let newArr = [...PrivatePaths]; // Create a copy of PrivatePaths array
      newArr[index] = {
        path: '/super-admin-dashboard',
        Content: () => (
          <Suspense fallback={<Preloader/>}>
            <Restrict />
          </Suspense>
        )
      }
      index = PrivatePaths.findIndex(item => item.path === '/all-admins');
      newArr[index] = {
        path: '/all-admins',
        Content: () => (
          <Suspense fallback={<Preloader/>}>
            <Restrict />
          </Suspense>
        )
      }
      setRestrictPrivatePaths(newArr);
    }
  }
 const filterRoutes = ()=>{
  
  let routesArr:any = PrivatePaths.map((item)=>{
    if(item?.path === '/patient-registration-and-result-entry')
    {
return item;
    }else{
      return {
        path: item?.path,
        Content: () => (
          <Suspense fallback={<Preloader/>}>
            {loading ?   <Preloader/> :<Restrict />}
          </Suspense>
        )
      }
    }
 
  });
  userAccess?.forEach((role:any) => {
    switch (role) {
        case Roles.REPORTING_ACCESS:
          routesArr[routesArr.findIndex((item:any) => item.path === '/user-reports')] = PrivatePaths.find((item)=>item?.path === '/user-reports');
            break;
       case Roles.APPOINTMENT_ACCESS:
  
        routesArr[routesArr.findIndex((item:any) => item.path === '/')] = PrivatePaths.find((item)=>item?.path === '/');
        break;
        case Roles.PATIENT_REGISTRATION_ACCESS || Roles.PATIENT_UPDATE_ACCESS || Roles.APPOINTMENT_ACCESS:
        routesArr[routesArr.findIndex((item:any) => item.path === '/patient-registration')] = PrivatePaths.find((item)=>item?.path === '/patient-registration');
        break;
        default:
          // setRestrictPrivatePaths(PrivatePaths);
            break;
    }
   
});
setRestrictPrivatePaths(routesArr);
 }

  return (
    <>
      <Routes>
        <Route element={<AuthRoute />}>
          {AuthPaths.map((route: RouteItem) => (
            <Route key={route.path} path={route.path} element={<route.Content />} />
          ))}
        </Route>
        {PublicPaths.map((route: RouteItem) => (
          <Route key={route.path} path={route.path} element={<route.Content />} />
        ))}
        <Route element={<PrivateRoute />}>
          {restrictPrivatePaths.map((route: RouteItem) => (
            <Route key={route.path} path={route.path} element={<Layout><route.Content /></Layout>} />
          ))}
        </Route>
        
      </Routes>
    </>
  );
};

export default RoutesHOC;