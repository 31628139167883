import React from 'react';

const AppointmentIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 2.518C3.46 2.541 3.072 2.592 2.729 2.708C2.14238 2.90442 1.60932 3.23422 1.17171 3.67148C0.734106 4.10874 0.403882 4.64154 0.207 5.228C1.49012e-08 5.849 0 6.615 0 8.149C0 8.244 1.11759e-08 8.292 0.013 8.33C0.0252768 8.36679 0.0459435 8.40021 0.0733657 8.42763C0.100788 8.45506 0.134214 8.47572 0.171 8.488C0.209 8.501 0.257 8.501 0.353 8.501H17.647C17.743 8.501 17.791 8.501 17.829 8.488C17.8658 8.47572 17.8992 8.45506 17.9266 8.42763C17.9541 8.40021 17.9747 8.36679 17.987 8.33C18 8.291 18 8.243 18 8.147C18 6.614 18 5.847 17.793 5.229C17.5964 4.64217 17.2663 4.10897 16.8287 3.67135C16.391 3.23372 15.8578 2.90362 15.271 2.707C14.928 2.592 14.539 2.541 14 2.518V5C14 5.39782 13.842 5.77936 13.5607 6.06066C13.2794 6.34196 12.8978 6.5 12.5 6.5C12.1022 6.5 11.7206 6.34196 11.4393 6.06066C11.158 5.77936 11 5.39782 11 5V2.5H7V5C7 5.39782 6.84196 5.77936 6.56066 6.06066C6.27936 6.34196 5.89782 6.5 5.5 6.5C5.10218 6.5 4.72064 6.34196 4.43934 6.06066C4.15804 5.77936 4 5.39782 4 5V2.518Z" fill="#2A2A2A"/>
      <path d="M16.4281 17.6372L16.4279 17.6375C15.9386 18.2904 15.3034 18.6327 14.3007 18.8125C13.2657 18.9981 11.9042 19 10 19H8C6.09584 19 4.73434 18.9981 3.69926 18.8125C2.69656 18.6327 2.0614 18.2904 1.57212 17.6375L1.57188 17.6372C1.06685 16.9644 0.787042 16.0534 0.643975 14.6352C0.500603 13.2138 0.5 11.358 0.5 8.83333V8.16667C0.5 8.10167 0.500012 8.04745 0.50057 8H17.4994C17.5 8.04745 17.5 8.10167 17.5 8.16667V8.83333C17.5 11.358 17.4994 13.2138 17.356 14.6352C17.213 16.0534 16.9332 16.9644 16.4281 17.6372Z" stroke="#2A2A2A"/>
      <path d="M5.5 1V5M12.5 1V5" stroke="#2A2A2A" strokeLinecap="round"/>
      <path d="M11.0591 10.5L11.8082 11.0255L8.56029 15.71H7.81121L6 13.1721L6.74908 12.4733L8.18575 13.815L11.0591 10.5Z" fill="#2A2A2A"/>
    </svg>
  );
};

export default AppointmentIcon;
