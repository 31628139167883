export enum Roles{
    PATIENT_REGISTRATION_ACCESS=10,
    PATIENT_UPDATE_ACCESS=11,
    APPOINTMENT_ACCESS=20,
    APPOINTMENT_UPDATE_ACCESS=21,
    APPOINTMENT_CHECKED_ACCESS=22,
    TOKEN_ACCESS=30,
    VITAL_ACCESS=40,
    UPDATE_VITAL_ACCESS=41,
    REPORTING_ACCESS=50,
    USER_CATEGORIES_ACCESS=60,
    UPDATE_USER_CATEGORY_ACCESS=61,
    DELETE_USER_CATEGORY_ACCESS=62,
    INSURANCE_COMPANY_ACCESS=70,
    UPDATE_INSURANCE_COMPANY_ACCESS=71,
    DELETE_INSURANCE_COMPANY_ACCESS=72,
    INSURANCE_SERVICES_ACCESS=80,
    UPDATE_INSURANCE_SERVICES_ACCESS=81,
    DELETE_INSURANCE_SERVICES_ACCESS=82,
  }