
import Preloader from '../../components/Preloader';
import { Suspense, lazy } from 'react';
const AddNew = lazy(()=>import('../../pages/Super-Admin/add-new'));
const EmployeDepartment = lazy(()=>import('../../pages/emplye-page/add-department'));
const EmpShift = lazy(()=>import('../../components/employ/emp-shift'));
const AddNewAdmins = lazy(()=>import('../../components/super-admin/add-new-admin'));
const AttachFile = lazy(()=>import('../../components/employ/attatch-file'));

const AddNewTest = lazy(()=>import('../../components/pathology/add-new-test/index.tsx'));
const Home =lazy(()=> import('../../pages/Home'))
const PatientRegistrationome =lazy(()=> import('../../pages/reg-form/RegForms'))
const Employ =lazy(()=> import('../../components/employ/BodyCard'))
const AddEmploye =lazy(()=> import('../../pages/emplye-page/add-employe/add-employe-form'))
const EmployeCategory =lazy(()=> import('../../pages/emplye-page/emp-category'))
const EmployeDetails = lazy(()=> import('../../pages/emplye-page/add-employe/emp-detail-form'))
const UserReports = lazy(()=> import('../../pages/reports/user-reports'))
const Insurances = lazy(()=> import('../../pages/Insurance/Insurances'))
const EmpServices = lazy(()=> import('../../components/AddEmpServices'))
const Companies = lazy(()=> import('../../pages/Insurance/Companies/company-dashboard'))
const AddNewCompany = lazy(()=> import('../../pages/Insurance/Companies/add-new'))
const PathologyDashboard = lazy(() => import('../../pages/pathalogy/dashboard'))
const RegisterPathology = lazy(() => import('../../pages/pathalogy/register'))
const PathologyRegistration = lazy(() => import('../../components/PathologyRegistration'))
const ReportTable = lazy(() => import('../../components/PathologyRegistration/report-table'))
const InsuranceServices = lazy(() => import('../../pages/Insurance/Services'))
const InsuranceReports = lazy(() => import('../../pages/Insurance/Insurances/insurance-reports'))
const SuperAdmin = lazy(() => import("../../pages/Super-Admin/dashboard"));
const NewAdmin = lazy(() => import("../../pages/Super-Admin/new-admin"));
const AddNewAdmin = lazy(() => import("../../components/super-admin/add-new-admin-form"));

const AccessConfigID =lazy(() => import('../../components/access-config'));

const AccessConfig = lazy(() => import('../../pages/AccessConfig'))

const PrivatePaths = [

{
  path: '/super-admin-dashboard',
  Content: () => (
    <Suspense fallback={<Preloader/>}>
      <SuperAdmin />
    </Suspense>
  )
},
{
  path: '/register-new-admin',
  Content: () => (
    <Suspense fallback={<Preloader/>}>
      <AddNewAdmin />
    </Suspense>
  )
},
{
  path: '/register-new-admin/:id',
  Content: () => (
    <Suspense fallback={<Preloader/>}>
      <AddNewAdmin />
    </Suspense>
  )
},
{
  path: '/new-admins',
  Content: () => (
    <Suspense fallback={<Preloader/>}>
      <AddNewAdmins />
    </Suspense>
  )
},
{
  path: '/new-admins/:id/:name',
  Content: () => (
    <Suspense fallback={<Preloader/>}>
      <AddNewAdmins />
    </Suspense>
  )
},
{
  path: '/all-admins',
  Content: () => (
    <Suspense fallback={<Preloader/>}>
      <NewAdmin />
    </Suspense>
  )
},


  {
    path: '/',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <Home />
      </Suspense>
    )
  },
  {
    path: '/patient-registration',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <PatientRegistrationome />
      </Suspense>
    )
  },
  {
    path: '/patient-registration:appointment',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <PatientRegistrationome />
      </Suspense>
    )
  },
  {
    path: '/employe',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <Employ />
      </Suspense>
    )
  },
  {
    path: '/employe-category',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <EmployeCategory />
      </Suspense>
    )
  },
  {
    path: '/employe-add',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AddEmploye />
      </Suspense>
    )
  },
  {
    path: '/employe-add/:id',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AddEmploye />
      </Suspense>
    )
  },
  {
    path: '/add-shift/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <EmpShift />
      </Suspense>
    )
  },
  {
    path: '/add-employe-details/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <EmployeDetails />
      </Suspense>
    )
  },
  {
    path: '/add-department',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <EmployeDepartment />
      </Suspense>
    )
  },
  {
    path: '/user-reports',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <UserReports />
      </Suspense>
    )
  },
  {
    path: '/insurances',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <Insurances />
      </Suspense>
    )
  },
  {
    path: '/companies',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <Companies />
      </Suspense>
    )
  },
  {
    path: '/attatch-file/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AttachFile />
      </Suspense>
    )
  },
  {

    path: '/emp-services/:empId/:name',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        < EmpServices/>
      </Suspense>
    )
},
{

    path: '/add-new-company',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AddNewCompany />

      </Suspense>
    )
  },
  {
    path: '/pathology',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <PathologyDashboard />

      </Suspense>
    )
  },
  {
    path: '/add-new-test',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AddNewTest />

      </Suspense>
    )
  },
  {
    path: '/pathology-register',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <RegisterPathology />
        </Suspense>
    )
  },
  {
    path: '/pathology-registration',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <PathologyRegistration />
      </Suspense>
    )
  },
  {

    path: '/insurance-services',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <InsuranceServices />

      </Suspense>
    )
  },
  {

    path: '/report-table',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <ReportTable />
      </Suspense>
    )
  },
  {

    path: '/insurance-reports',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <InsuranceReports />
      </Suspense>
    )
  },
  {

    path: '/access-config',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AccessConfig />
      </Suspense>
    )
  },
  {

    path: '/user-access/:id',
    Content: () => (
      <Suspense fallback={<Preloader/>}>
        <AccessConfigID />
      </Suspense>
    )
  }
 


  

];
export default PrivatePaths;