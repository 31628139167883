import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAuth } from '../../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import logo from '../../../assets/SVG/logo.svg';
import React from 'react';
interface HeaderProps {
  drawerToggler: ()=> void; 
}
const Header: React.FC<HeaderProps> = ({drawerToggler}) => {
  const { logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    if (isLoggedIn) {
      logout();
      navigate('/');
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='open drawer'
            sx={{ mr: 2 }}
            onClick={()=>drawerToggler()}
          >
            <Menu/>
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              width: '100%',
              margin: 'auto',
            }}
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <img src={logo} style={{height:'40px'}}/>
            </Typography>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              Hospital Management System
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'end' }}>
              {/* <PrimarySearchBar /> */}
              {isLoggedIn && (
                <Button
                  onClick={handleLogout}
                  sx={{
                    background: 'white',
                    fontWeight: '500',
                    py: '6px',
                    marginLeft: '20px',
                    ':hover': {
                      bgcolor: 'gray',
                      color: 'white',
                    },
                  }}
                  variant='outlined'
                >
                  Logout
                </Button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
