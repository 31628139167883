import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; 
import CalendarIcon from '../../../assets/SVG/calendarIcon';
import UserIcon from '../../../assets/SVG/userIcon/index';
import './style.css';
import { ArrowRight, Close, GridView, SettingsSuggest } from '@mui/icons-material';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import InsuranceIcon from '../../../assets/SVG/insuranceIcon';
import PathologyIcon from '../../../assets/SVG/pathology';
import ReportIcon from '../../../assets/SVG/ReportIcon';
import AppointmentIcon from '../../../assets/SVG/AppointmentIcon';
import EmployIcon from '../../../assets/SVG/EmployIcon';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccessConfigIcon from '../../../assets/SVG/AccessConfigIcon';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from '../../../redux/reducers/profileSlice';

import { Roles } from '../../../enums';

// import AddNewTest from '../../pathology/add-new-test';




interface SideBarProps {
isOpen:boolean,
closeDrawer: ()=> void;
}
const SideBar:React.FC<SideBarProps> = ({isOpen,closeDrawer}) => {
  
  const isSmallScreen = useMediaQuery(" (max-width:960px)");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [activeSubMenu, setActiveSubMenu] = useState<string>(''); // Change activeSubMenu to string
  const location = useLocation();
  const profile = useSelector((state : any) => state.profileSlice.profile)
  const category = profile?.data?.userType
const userAccess = profile?.data?.roles;
const profileLoading = useSelector((state : any) => state.profileSlice.loading);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProfileData() as any)
  },[])
  const options = [
    
    {
      title: 'Appointments',
      icon: <AppointmentIcon />,
      patch: '/',
      subMenu: [
        {
          title: 'Registration',
          route: '/patient-registration',
        }
      ],
    },

    {
      title: 'Employe',
      icon: <EmployIcon />,
      patch:'/employe',
      subMenu: [
        {
          title: 'Category',
          route: '/employe-category',
        },
        {
          title: 'Add Employe',
          route: '/employe-add',
        },
        {
          title: 'Departments',
          route: '/add-department',
        },
      ],
    },
    {
      title: 'Insurance',
      icon: <InsuranceIcon />,
      patch:'/insurances',
      subMenu: [
       
        {
          title: 'Companies',
          route: '/companies',
        },
        {
          title: 'Add Services',
          route: '/insurance-services',
        },
        {
          title: 'Reports',
          route: '/insurance-reports',
        }
      ],
    }
    ,{
      title: 'Pathology',
      icon: <PathologyIcon />,
      patch:'/pathology',
      
    },
    {
      title: 'Reports',
      icon: <ReportIcon />,
      patch:'/user-reports',
      
    },

    
      {
      title: 'Access Config',
      icon: <AccessConfigIcon />,
      patch:'/access-config',
     
    },
    {
      title: 'Counter',
      icon: <GridView />,
      patch:'/counter',
     
    }
  ];
//   userAccess?.forEach((role:any) => {
//     switch (role) {
//         case Roles.REPORTING_ACCESS:
//             options.push({
//                 title: 'Reports',
//                 icon: <ReportIcon />,
//                 patch: '/user-reports',
//             });
//             break;
       
//         default:
//             break;
//     }
// });
if (category?.includes('superAdmin')) {
  options.push({
    title: 'Super Admin',
    icon: <AdminPanelSettingsIcon />,
    patch: '/super-admin-dashboard',
    subMenu: [
     
      {
        title: 'Admins',
        route: '/all-admins',
      },
     
    ],
  });
}
  const handleItemClick = (index: number) => {
    if (options[index].title === 'Appointments') {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/'); // Navigate to '/' route
    } 
  


    else if(options[index].title === 'Counter')
    {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/counter'); // Navigate to '/' route
    }
    else if(options[index].title === 'Insurance')
    {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/insurances'); // Navigate to '/' route
    }
    else if(options[index].title === 'Reports')
    {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/user-reports'); // Navigate to '/' route
    }
    else if(options[index].title === 'Employe')
    {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/employe'); // Navigate to '/' route
    }
    else if(options[index].title === 'Pathology')
    {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/pathology'); // Navigate to '/' route
    }   else if(options[index].title === 'Access Config')
    {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/access-config'); // Navigate to '/' route
    }
    else if(options[index].title === 'Super Admin'){
      setActiveIndex(activeIndex === index ? null : index); // Toggle active index for accordion
      navigate('/super-admin-dashboard'); }
    else {
      setActiveIndex(activeIndex === index ? null : index);
    }
    
  };

  const handleSubMenuClick = (route: string) => {
    navigate(route);
    setActiveSubMenu(route); // Update activeSubMenu state with the route
  };
  useEffect(()=>{
  
    options.map((item,index)=>{
      if(item.patch === location.pathname){
        setActiveIndex(index);
      }else if(item?.subMenu )
      { 
        
        item.subMenu.map((item2)=>{
          if(item2.route === location.pathname)
          {
            setActiveIndex(index);
        setActiveSubMenu(item2.route );
          }
          
        })
        
      }
      
    })
    
  },[])

  return (
    <div>
      {
         isSmallScreen ?  <Drawer
        anchor="left"
        
        open={isOpen}
        
        sx={{ width: isSmallScreen ? '80%' : 250 }}
        onClick={closeDrawer}
      >
        <div>
          <IconButton onClick={closeDrawer} sx={{ alignSelf: 'flex-end' }}>
            <Close />
          </IconButton>
        </div>
        <List>
          {options.map((option, index) => (
            <React.Fragment key={index}>
              <ListItem key={option.title} disablePadding sx={{ background: activeIndex === index ? '#f9fbff' : 'transparent', borderLeft: activeIndex === index ? '4px solid #077DD3' : 'transparent' }}>
                <ListItemButton onClick={() => handleItemClick(index)}>
                  <ListItemIcon
                    sx={{
                      filter: activeIndex === index ? 'invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)' : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
                    }}
                  >
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      filter: activeIndex === index ? 'invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)' : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
                    }}
                  >
                    {option.title}
                  </ListItemText>
                  {activeIndex === index ? <ArrowRight color='primary' /> : ''}
                </ListItemButton>
              </ListItem>
              {activeIndex === index && (
                <List sx={{ pl: isSmallScreen ? '0' : '80px' }}>
                  {option.subMenu?.map((subOption, subIndex) => (
                    <ListItem
                      className={activeSubMenu === subOption.route ? 'active' : ''}
                      key={subIndex}
                      sx={{
                        color: activeSubMenu === subOption.route ? '#077DD3' : '#3F3F3F',
                        cursor: 'pointer',
                        background: activeSubMenu === subOption.route ? '#f9fbff' : 'transparent',
                        borderLeft: activeSubMenu === subOption.route ? '4px solid #077DD3' : 'transparent',
                      }}
                      onClick={() => handleSubMenuClick(subOption.route)}
                    >
                      {activeSubMenu === subOption.route ? <ArrowRight /> : <ArrowRight />} <ListItemText primary={subOption.title} />
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer> :<Box
    className={isOpen ? 'drawer open' : 'drawer closed'}
         role="presentation"
        
       >
         <List>
           {options.map((option, index) => (
             
             <React.Fragment key={index}>
                           
               <ListItem key={option.title} disablePadding sx={{background: activeIndex === index ? '#f9fbff' : 'transparent', borderLeft:activeIndex === index ?'4px solid #077DD3' : 'transparent',}}>
                 <ListItemButton onClick={() => handleItemClick(index)} >
                   <ListItemIcon
                     sx={{
                       filter:
                         activeIndex === index
                           ? 'invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)'
                           : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
                     }}
                   >
                      {option.icon} 
                   </ListItemIcon>
                   <ListItemText
                     sx={{
                       filter:
                         activeIndex === index
                           ? 'invert(24%) sepia(98%) saturate(6571%) hue-rotate(183deg) brightness(93%) contrast(92%)'
                           : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
                       
                     }}
                   >
                     {option.title}
                   </ListItemText>
                   {activeIndex === index ? <ArrowRight color='primary'/> : ''}
                 </ListItemButton>
               </ListItem>
               {activeIndex === index && (
                 <List sx={{ pl: '80px' }}>
                   {option.subMenu?.map((subOption, subIndex) => (
                     <ListItem
                     className={activeSubMenu === subOption.route ? 'active' : ''}
                       key={subIndex}
                       sx={{
                         color: activeSubMenu === subOption.route ? '#077DD3' : '#3F3F3F', // Check if activeSubMenu matches route
                         cursor: 'pointer',
                         background: activeSubMenu === subOption.route ? '#f9fbff' : 'transparent',
                         borderLeft:activeSubMenu === subOption.route ?'4px solid #077DD3' : 'transparent',
                       }}
                       onClick={() => handleSubMenuClick(subOption.route)} // Handle sub menu item click
                       
                     >
                      {activeSubMenu === subOption.route ? <ArrowRight/> : <ArrowRight/>} <ListItemText primary={subOption.title} />
                     </ListItem>
                   ))}
                 </List>
               )}
             </React.Fragment>
           ))}
         </List>
       </Box>  
      }
    </div>
  );
};
 


export default SideBar;